import {
  ArrowDownCircleIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import { Redirect } from "../features/nav/Redirect";
import {
  ArrowTopRightOnSquareIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { useState } from "react";
import { saveFileFromBlob, saveFileFromJson } from "../utils/download";
import { download } from "../features/survey/surveyAPI";
import { handleError } from "../features/errors/errorUtils";
import { CenteredSpinner } from "../components/Spinner";
import axios from "axios";

type DownloadState = "notStarted" | "started" | "done" | "error";

export default function SurveyExportView() {
  const { surveyId } = useParams();
  const [accordionOpen, setAccordionOpen] = useState<"json" | "txt" | null>(
    null
  );

  if (!surveyId) return <Redirect to="/drafter/surveys" />;
  return (
    <div className="flex-1 max-h-screen flex flex-col justify-center items-center">
      <div className="py-24 max-w-2xl w-full">
        <div className="mx-auto flex h-12 w-12 items-center justify-center">
          <ArrowDownCircleIcon
            aria-hidden="true"
            className="h-12 w-12 text-gray-700"
          />
        </div>
        <div className="">
          <h3 className="text-base font-semibold text-gray-900 text-center">
            Let's get ready for programming.
          </h3>
          <div className="mt-6 space-y-4">
            <ExportJsonAccordion
              surveyId={surveyId}
              isOpen={accordionOpen === "json"}
              onToggle={() => {
                if (accordionOpen === "json") {
                  setAccordionOpen(null);
                  return;
                }
                setAccordionOpen("json");
              }}
            />
            <ExportTextAccordion
              surveyId={surveyId}
              isOpen={accordionOpen === "txt"}
              onToggle={() => {
                if (accordionOpen === "txt") {
                  setAccordionOpen(null);
                  return;
                }
                setAccordionOpen("txt");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function DownloadButton({
  state,
  startDownload,
}: {
  state: DownloadState;
  startDownload: (force: boolean) => void;
}) {
  if (state === "done") {
    return (
      <p className="text-sm italic text-gray-700">
        Done! Check your downloads folder.{" "}
        <button
          type="button"
          className="font-bold hover:underline"
          onClick={() => startDownload(true)}
        >
          Retry
        </button>
      </p>
    );
  }
  if (state === "error") {
    return (
      <div className="text-sm">
        <p className="text-red-700 italic">
          Sorry, we had trouble downloading your file. Please retry or contact
          your IncQuery Survey Director for assistance.
        </p>
        <button
          type="button"
          className="font-bold hover:underline"
          onClick={() => startDownload(true)}
        >
          Retry
        </button>
      </div>
    );
  }
  if (state === "started") {
    return (
      <div>
        <CenteredSpinner size="sm" />
        <div className="pt-1 text-sm italic">
          Preparing file. (This may take a moment.) Download will start
          automatically.
        </div>
      </div>
    );
  }
  return (
    <button
      type="button"
      onClick={() => startDownload(false)}
      className="block text-center w-full rounded bg-gray-100 px-6 py-3 text-sm hover:bg-gray-200"
    >
      <ArrowDownTrayIcon className="w-5 h-5 inline-block text-gray-600 mr-2" />
      Download
    </button>
  );
}

function ExportJsonAccordion({
  surveyId,
  onToggle,
  isOpen,
}: {
  surveyId: string;
  isOpen: boolean;
  onToggle: () => void;
}) {
  const [downloadState, setDownloadState] =
    useState<DownloadState>("notStarted");
  async function startDownload(force = false) {
    try {
      if (downloadState === "started" || !surveyId) return;
      setDownloadState("started");
      const { blob, filename } = await download(surveyId, force);
      saveFileFromBlob(blob, filename);
      setDownloadState("done");
    } catch (err) {
      handleError(err);
      setDownloadState("error");
    }
  }
  return (
    <div className="border rounded">
      <button
        onClick={onToggle}
        className="hover:bg-gray-100 rounded py-2 px-4 w-full text-left"
      >
        {isOpen ? (
          <ChevronDownIcon className="mr-5 w-4 h-5 inline-block" />
        ) : (
          <ChevronRightIcon className="mr-5 w-4 h-5 inline-block" />
        )}
        Export into IncQuery editor (recommended)
      </button>
      {isOpen && (
        <div className="mt-2 px-12">
          <table>
            <tbody>
              <tr>
                <td className="text-sm w-64">1. Download survey export</td>
                <td className="py-2 pl-6">
                  <DownloadButton
                    state={downloadState}
                    startDownload={startDownload}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-sm w-64">
                  2. Go to your IncQuery dashboard
                </td>
                <td className="py-2 pl-6">
                  <a
                    href="https://app.incquery.com/s"
                    target="_blank"
                    className="block text-center w-full rounded bg-gray-100 px-6 py-3 text-sm hover:bg-gray-200"
                  >
                    <ArrowTopRightOnSquareIcon className="w-5 h-5 inline-block text-gray-600 mr-2" />
                    Open
                  </a>
                </td>
              </tr>
              <tr>
                <td className="text-sm w-64">3. Click "Create Survey"</td>
                <td className="py-2 pl-6">
                  <img
                    src="/drafter/images/create-survey.png"
                    width={300}
                    className="rounded border"
                  />
                </td>
              </tr>
              <tr>
                <td className="text-sm w-64">4. Enter survey details</td>
                <td className="py-2 pl-6">
                  <img
                    src="/drafter/images/survey-details.png"
                    width={300}
                    className="rounded border"
                  />
                </td>
              </tr>
              <tr>
                <td className="text-sm w-64">5. Upload your survey export</td>
                <td className="py-2 pl-6">
                  <img
                    src="/drafter/images/upload-json.png"
                    width={300}
                    className="rounded border"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

function ExportTextAccordion({
  surveyId,
  isOpen,
  onToggle,
}: {
  surveyId: string;
  isOpen: boolean;
  onToggle: () => void;
}) {
  async function downloadFile(endpoint: "download-docx" | "download-text") {
    {
      const { data, headers } = await axios.post(
        `/api/surveys/${surveyId}/${endpoint}`,
        {},
        {
          responseType: "blob",
        }
      );
      saveFileFromBlob(
        data,
        headers["content-disposition"].replaceAll("attachment; filename=", "")
      );
    }
  }

  return (
    <div className="border rounded">
      <button
        onClick={onToggle}
        className="hover:bg-gray-100 rounded py-2 px-4 w-full text-left "
      >
        {isOpen ? (
          <ChevronDownIcon className="mr-5 w-4 h-5 inline-block" />
        ) : (
          <ChevronRightIcon className="mr-5 w-4 h-5 inline-block" />
        )}
        Export to document
      </button>
      {isOpen && (
        <div className="my-2 px-6">
          <div className="text-sm">{`If you'd like to continue working in a document, use this button to download. However, you will no longer be able to use AI to automatically import content into the IncQuery survey editor.`}</div>
          <div className="mt-4 mb-6 space-y-2">
            <button
              className="py-2 px-6 rounded bg-gray-100 hover:bg-gray-200 text-sm block"
              onClick={() => downloadFile("download-text")}
            >
              Download as .txt
            </button>
            <button
              className="py-2 px-6 rounded bg-gray-100 hover:bg-gray-200 text-sm block"
              onClick={() => downloadFile("download-docx")}
            >
              Download as .docx
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
